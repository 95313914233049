import * as React from "react";
import { graphql, useStaticQuery } from "gatsby";

import { SocialIcon } from "react-social-icons";

import resolveConfig from "tailwindcss/resolveConfig";
const tailwindConfig = require("../../tailwind.config");

const fullConfig = resolveConfig(tailwindConfig);
const primary = fullConfig.theme.colors.primary;

const SocialIcons = ({
  bgTransparent = false,
  containerClassName = "",
  iconClassName = "",
  invert = false,
  ...rest
}) => {
  const data = useStaticQuery(graphql`
    query SocialIconsQuery {
      allSite {
        nodes {
          siteMetadata {
            socialUrls
          }
        }
      }
    }
  `);
  const socialUrls = data.allSite.nodes[0].siteMetadata.socialUrls;

  const bgColor = invert ? "#FFF" : primary;
  const fgColor = invert ? primary : "#FFF";

  return (
    <div className={`${containerClassName}`} {...rest}>
      {socialUrls.map((url, i) => (
        <SocialIcon
          url={url}
          key={i}
          className={`${
            bgTransparent ? "hover:bg-primary" : ""
          } ${iconClassName}`}
          bgColor={bgTransparent ? "transparent" : bgColor}
          fgColor={fgColor}
        />
      ))}
    </div>
  );
};

export default SocialIcons;
